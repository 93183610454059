import React, { FC } from 'react'
import { Callout } from '@radix-ui/themes';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';

type ErrorCalloutProps = {
    message: string,
    color: 'red' | 'orange',
}

const ErrorCallout: FC<ErrorCalloutProps> = ({ message, color }) => {
    return (
        <Callout.Root color={color} role='alert' className='mt-4'>
            <Callout.Icon>
                <ExclamationTriangleIcon />
            </Callout.Icon>
            <Callout.Text>
                {message}
            </Callout.Text>
        </Callout.Root>
    )
}

export default ErrorCallout