'use client';

import React, { FC } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { TreeViewBaseItem } from '@mui/x-tree-view/models';

import { Box, Grid } from '@radix-ui/themes';

import ErrorCallout from '@/components/ui/server/error';
import { useHierarchy } from '@/lib/api';
import { SkeletonBrands } from '@/components/ui/client/loader';

export interface BrandsProps {
    className: string | undefined;
}

const Brands: FC<BrandsProps> = ({ className }) => {
    const { hierarchy, isLoading, isError } = useHierarchy();

    if (isLoading) return <SkeletonBrands />
    if (isError) return <ErrorCallout color='red' message={isError.toString()}/>

    if (!hierarchy || hierarchy.length === 0) return (
        <ErrorCallout color='orange' message='Vendors empty'/>
    )

    return (
        <Grid
            columns={{
                initial: '1',
                sm: '2',
                md: '3',
                lg: '4'
            }}
            gap='3'
            width='auto'
            className={className}
        >
            {hierarchy?.map((item: TreeViewBaseItem) => (
                <Link
                    key={item.id}
                    className='border border-solid border-gray-100 text-center rounded justify-center cursor-pointer p-6 hover:bg-gray-100 min-h-36'
                    href={`/catalog/${item.id}`}
                >
                    <Box className='relative h-full flex items-end justify-center'>
                        <Image
                            loading='lazy'
                            src={`/pictures/vendor/${item.id}.png`}
                            alt={ item.label }
                            fill
                            style={{ objectFit: 'contain', paddingBottom: '10px' }}
                        />
                        <Box className='font-semibold text-xs top-4 relative'>
                            {item.label}
                        </Box>
                    </Box>
                </Link>
            ))}
        </Grid>
    );
};

export default Brands;
