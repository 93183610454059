import React, { FC } from 'react';
import { Box, Grid, Skeleton, Spinner } from '@radix-ui/themes';

export const SkeletonCatalog: FC = () => {
    return (
        <Box className='mt-4'>
            <Skeleton width='100%'  height={{ initial: '315px', md: '146px' }} className='mb-3' />
            <Skeleton width='100%'  height={{ initial: '315px', md: '146px' }} className='mb-3' />
            <Skeleton width='100%'  height={{ initial: '315px', md: '146px' }} className='mb-3' />
            <Skeleton width='100%'  height={{ initial: '315px', md: '146px' }} className='mb-3' />
            <Skeleton width='100%'  height={{ initial: '315px', md: '146px' }} className='mb-3' />
            <Skeleton width='100%'  height={{ initial: '315px', md: '146px' }} className='mb-3' />
            <Skeleton width='100%'  height={{ initial: '315px', md: '146px' }} className='mb-3' />
            <Skeleton width='100%'  height={{ initial: '315px', md: '146px' }} className='mb-3' />
            <Skeleton width='100%'  height={{ initial: '315px', md: '146px' }} className='mb-3' />
        </Box>
    );
};

export const SkeletonMenu: FC = () => {
    return (
        <Box>
            <Skeleton width='100%' height='21px' className='mb-2' />
            <Skeleton width='100%' height='21px' className='mb-2' />
            <Skeleton width='100%' height='21px' className='mb-2' />
            <Skeleton width='100%' height='21px' className='mb-2' />
            <Skeleton width='100%' height='21px' className='mb-2' />
            <Skeleton width='100%' height='21px' className='mb-2' />
            <Skeleton width='100%' height='21px' className='mb-2' />
        </Box>
    );
};

export const SkeletonBrands: FC = () => {
    return (
        <Grid
            columns={{
                initial: '1',
                sm: '2',
                md: '3',
                lg: '4'
            }}
            gap='3'
            width='auto'
            className='my-3'
        >
            <Skeleton width='100%' height='144px' className='mb-2' />
            <Skeleton width='100%' height='144px' className='mb-2' />
            <Skeleton width='100%' height='144px' className='mb-2' />
            <Skeleton width='100%' height='144px' className='mb-2' />
        </Grid>
    );
};

interface LoaderProps {
    className?: string;
}

export const Loader: FC<LoaderProps> = ({ className }) =>
    <Box className={className}>
        <Spinner size='3' className='absolute right-3 top-6' />
    </Box>
