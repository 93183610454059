import useSWR from 'swr';
import { Product } from '@/lib/entiry/product';

export const fetcher = (url: string | URL | Request) => fetch(url).then((res) => res.json());

export const baseUrl = '/api';

export function useHierarchy() {
    const { data, error, isLoading } = useSWR(`${baseUrl}/hierarchy`, fetcher);

    return {
        hierarchy: data,
        isLoading,
        isError: error
    }
}

export const PAGE_SIZE = 50;

export interface CatalogProductsResponse {
    total: number;
    products: Product[];
}

// export function useProducts(
//     page: number,
//     vendor: string,
//     category?: string
// ) {
//     const offset = page < 2 ? 0 : (page - 1) * PAGE_SIZE;
//     const categoryParam = category ? `&category=${category}` : '';
//     const { data, error, isLoading } = useSWR(`${baseUrl}/products/vendor/${vendor}?offset=${offset}&limit=${PAGE_SIZE}${categoryParam}`, fetcher);
//
//     return {
//         products: data,
//         isLoading,
//         isError: error
//     }
// }

export function useProduct(product: string) {
    const { data, error, isLoading } = useSWR(`${baseUrl}/product/${product}`, fetcher);

    return {
        product: data,
        isLoading,
        isError: error
    }
}
